import React from "react";
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
// import { Box } from '@material-ui/core';
// import { useStyles } from './styles'
// import { useScopes } from "app/contexts/ScopesContext";

const Desktop = React.memo(() => {
  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_sidebar'
      slotId= 'kultivicom_sidebar'
    />
  )
});


const AdsSideBar = () => {
  return (<></>);
  // const classes = useStyles();
  // const { scopes } = useScopes();

  // return (
  //   !scopes?.includes("student_no_ads_display") &&
  //   <div align="center">
  //     <Box className={classes.ads}>Publicidade</Box>
  //     <Desktop />
  //   </div>
  // );
}

export default React.memo(AdsSideBar)
