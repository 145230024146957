import React from "react";
// import { isMobile } from 'react-device-detect';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
// import { Box } from '@material-ui/core';
// import { useStyles } from './styles'
// import { useScopes } from "app/contexts/ScopesContext";

const Desktop = React.memo(() => {

  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_top'
      slotId='kultivicom_top'
    />
  )
});

const MobileApp = () => {

  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_app_banner_top'
      slotId='kultivicom_app_banner_top'
    />
  )
}

const AdsTop = (props) => {
  return (<></>);
  // const classes = useStyles();
  // const { scopes } = useScopes();

  // return (
  //   !scopes?.includes("student_no_ads_display") &&
  //   <Box {...props}>
  //     <div align="center">
  //       <Box className={classes.ads}>Publicidade</Box>
  //       { isMobile ? <MobileApp /> : <Desktop /> }
  //     </div>
  //   </Box>
  // );
}

export default React.memo(AdsTop)
